import React, { Component } from "react";
import HeaderNavBar from "../HeaderNavBar/HeaderNavBar";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Footer from "../Footerpage/Footer";
import "../References/References.css";

let userSelectedLanguage;

class References extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");

    window.scrollTo(0, 0);
  }
  callback = () => {
    this.setState({
      reRender: true,
    });
  };

  render() {
    return (
      <div>
        <HeaderNavBar callBack={this.callback} />
        <div className="ID_References">
          <br></br>
          <br></br>
          <div
            style={{
              textTransform: "uppercase",
              fontWeight: 500,
              fontSize: "30px",
              textAlign: "center",
            }}
          >
            References
          </div>
          <br></br>
          <br></br>
          <br></br>
          <section
            style={{
              fontSize: "25px",
              fontWeight: "800",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Coming Fall 2024
          </section>
          <br></br>
          <br></br>
          <br></br>
          <section>
            1. Introduction These Website Standard Terms And Conditions (these
            “Terms” or these “Website Standard Terms And Conditions”) contained
            herein on this webpage, shall govern your use of this website,
            including all pages within this website (collectively referred to
            herein below as this “Website”). These Terms apply in full force and
            effect to your use of this Website and by using this Website, you
            expressly accept all terms and conditions contained herein in full.
            You must not use this Website, if you have any objection to any of
            these Website Standard Terms And Conditions. This Website is not for
            use by any minors (defined as those who are not at least 18 years of
            age), and you must not use this Website if you a minor.
          </section>
        </div>
        <ScrolltoTop />
        <Footer />
      </div>
    );
  }
}
export default References;
