import React, { Component } from "react";
import HeaderNavBar from "../HeaderNavBar/HeaderNavBar";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Footer from "../Footerpage/Footer";
import TranslationFile from "../Translation/Translation_corp.json";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./Credits.css";

let userSelectedLanguage;

class Credits extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollToContactUs() {
    this.props.callBack();
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    window.scrollTo(0, 0);
  }

  callback = () => {
    this.setState({
      reRender: true,
    });
  };

  render() {
    return (
      <div>
        <HeaderNavBar isCorpPortal={true} />
        <div className="credits-div">
          <br></br>
          <br></br>
          <div
            style={{
              // textTransform: "uppercase",
              fontWeight: 700,
              fontSize: "30px",
              textAlign: "center",
            }}
          >
            Credits
          </div>
          <br></br>
          <h3 style={{
            fontSize: "25px",
            fontWeight: "800",
          }}>Freepik :</h3>
          <section
            style={{
              fontSize: "20px",
              fontWeight: "750",
            }}
          >
            Our appreciation to freepik for giving access to us and the world to great quality images.
          </section>
          <br></br>
          <br></br>
          <Row>
            <Col md="auto">1.</Col>
            <Col>Blue print with laptop and copy space</Col>
          </Row>
          <Row>
            <Col md="auto"></Col>
            <Col><a href="https://www.freepik.com/free-photo/blue-print-with-laptop-copy-space_6413502.htm" target="blank">https://www.freepik.com/free-photo/blue-print-with-laptop-copy-space_6413502.htm</a></Col>
          </Row>
          <br></br>
          <Row>
            <Col md="auto">1.</Col>
            <Col>Blue print with laptop and copy space</Col>
          </Row>
          <Row>
            <Col md="auto"></Col>
            <Col><a href="https://www.freepik.com/free-photo/blue-print-with-laptop-copy-space_6413502.htm" target="blank">https://www.freepik.com/free-photo/blue-print-with-laptop-copy-space_6413502.htm</a></Col>
          </Row>
          <br></br>
        </div>
        <ScrolltoTop />
        <Footer />

      </div>
    );
  }
}

export default Credits;
