import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import footer__logo from "../../Assests/r1.jpg";
import { Row, Col } from "react-bootstrap";
import "./Footer.css";
import TranslationFile from "../Translation/Translation_corp.json";
import config from "../../Configuration/Config";
import { HashLink } from "react-router-hash-link";

let userSelectedLanguage;

export default function Footer(props) {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  const scriptURL =
    "https://script.google.com/macros/s/AKfycbyLuamRvfdsObvyflCqewspaYhzK1NXWYJekui7pGWdXD8lTQU4gbcUPwoCar4vbJln/exec";
  const toast = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const response = await fetch(scriptURL, {
      method: "POST",
      body: new FormData(e.target),
    });

    if (response.ok) {
      setIsLoading(false);
      toast.current.show({
        severity: "success",
        summary: TranslationFile.SuccessText[userSelectedLanguage],
        detail: TranslationFile.ThankForSubscribing[userSelectedLanguage],
        sticky: true,
      });
      setTimeout(() => {
        e.target.reset();
      }, 2000);
      setTimeout(() => {
        toast.current.clear();
      }, 5000);
    }
  };

  return (
    <footer>
      <div className="footer--bottom">
        <div>
          <div className={"footer--bottom__col"}>
            <div
              className="footer--bottom__cell"
              style={{ marginTop: "-20px" }}
            >
              <img
                alt
                src={footer__logo}
                className="footer_Logo_image"
                width="300px"
              />
            </div>
          </div>

          <div className={"footer--bottom__col"}>
            <div className={"footer--bottom__cell--links"}>
              <div className="footer--bottom__cell--links--inner">
                <center>
                  <h3 className="footer_title1">
                    {TranslationFile.FooterOnlineServices[userSelectedLanguage]}
                  </h3>
                </center>
                <section className="footerSection">
                  {/* <ul className="footer--bottom__ul"> */}
                  <li>
                    <a
                      className="footer--bottom_sub"
                      href={config.annualReport.url}
                    >
                      {/* annualReport */}
                      <center>
                        {
                          TranslationFile.FooterAnnualReport[
                            userSelectedLanguage
                          ]
                        }
                      </center>
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer--bottom_sub"
                      // href={config.SRU.url}
                      href={config.apps.url + "SRU_IncomeDeclaration"}
                    >
                      {/* Income Declaration */}
                      <center>
                        {
                          TranslationFile.IncomeDeclaration2[
                            userSelectedLanguage
                          ]
                        }
                      </center>
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer--bottom_sub"
                      href="/for-accounting-companies/"
                    >
                      <center>
                        {
                          TranslationFile.FooterForAccountingCompany[
                            userSelectedLanguage
                          ]
                        }
                      </center>
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer--bottom_sub"
                      href="/microsoft-business-central-development/"
                    >
                      <center>
                        {
                          TranslationFile.FooterMicrosoftBusinessCentral[
                            userSelectedLanguage
                          ]
                        }
                      </center>
                    </a>
                  </li>
                  {/* </ul> */}
                </section>
              </div>
            </div>

            <div className={"footer--bottom__cell--links"}>
              <div className="footer--bottom__cell--links--inner">
                <h3 className="footer_title2">
                  <center>
                    {TranslationFile.FooterFAQReferences[userSelectedLanguage]}
                  </center>
                </h3>
                <section className="footerSection">
                  {/* <ul className="footer--bottom__ul"> */}
                  <li>
                    <a className="footer--bottom_sub" href="/terms-of-usage/">
                      <center>
                        {
                          TranslationFile.FooterTermsofUsage[
                            userSelectedLanguage
                          ]
                        }
                      </center>
                    </a>
                  </li>
                  <li>
                    <a className="footer--bottom_sub" href="/data-security/">
                      <center>
                        {
                          TranslationFile.FooterDataSecurity[
                            userSelectedLanguage
                          ]
                        }
                      </center>
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer--bottom_sub"
                      href="/customer-references/"
                    >
                      <center>
                        {
                          TranslationFile.FooterProjectReferences[
                            userSelectedLanguage
                          ]
                        }
                      </center>
                    </a>
                  </li>
                  <li>
                    <a className="footer--bottom_sub" href="/for-investors/">
                      <center>
                        {" "}
                        {
                          TranslationFile.FooterForInvestors[
                            userSelectedLanguage
                          ]
                        }
                      </center>
                    </a>
                  </li>
                  <li>
                    <a className="footer--bottom_sub" href="/credits">
                      <center>
                        {TranslationFile.FooterCredits[userSelectedLanguage]}
                      </center>
                    </a>
                  </li>
                  {/* </ul> */}
                </section>
              </div>
            </div>

            <div className={"footer--bottom__cell--links"}>
              <div className="footer--bottom__cell--links--inner">
                <h3 className="footer_title3">
                  <center>
                    {TranslationFile.FooterAboutUs[userSelectedLanguage]}
                  </center>
                </h3>
                <section className="footerSection">
                  {/* <ul className="footer--bottom__ul"> */}
                  <li>
                    {/* <a className="footer--bottom_sub"> */}
                      <HashLink to="/about-us#why-rebelSkool" target="_blank">
                        <center>
                          {TranslationFile.FooterwhyRBL[userSelectedLanguage]}
                        </center>
                      </HashLink>
                    {/* </a> */}
                  </li>
                  <li>
                    {/* <a
                      className="footer--bottom_sub"
                      href="/about-us#price-policy"
                    > */}
                      <HashLink to="/about-us#RebelSkool-Price-Policy" target="_blank">
                      <center>
                        {
                          TranslationFile.FooterRBLPricePolicy[
                            userSelectedLanguage
                          ]
                        }
                      </center>
                      </HashLink>
                    {/* </a> */}
                  </li>
                  {/* </ul> */}
                </section>
              </div>
            </div>

            <div
              className={"footer--bottom__cell--links"}
              id="footer--bottom-__cell--last--contact"
            >
              <div className="footer--bottom__cell--links--inner">
                <h3 className="tw-mb-4">
                  {" "}
                  <center>
                    {TranslationFile.ContactUsHeader[userSelectedLanguage]}{" "}
                  </center>
                </h3>
                <div className="footer-li-style">
                  <li
                    style={{
                      listStyle: "none",
                      fontSize: "26px",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    RebelSkool AB
                  </li>
                  <li style={{ listStyle: "none" }}>Bollvägen 11 A</li>
                  <li style={{ listStyle: "none" }}>523 60 Gällstad</li>
                  <li style={{ listStyle: "none" }}> Org.Nr.: 559015-5395</li>
                  <li style={{ listStyle: "none" }}>
                    {" "}
                    {TranslationFile.FooterContactNumber[userSelectedLanguage]}
                  </li>
                  <li
                    style={{
                      listStyle: "none",
                      fontStyle: "italic",
                    }}
                  >
                    <a
                      href="mailto:info@rebelskool.se"
                      id="footer_email_contact_text"
                    >
                      info@rebelskool.se
                    </a>
                  </li>

                  <li style={{ listStyle: "none" }}>Godkänt för F-skatt</li>
                </div>
                <br></br>

                <p className="footer-subscription-text">
                  <span>
                    {TranslationFile.FooterSubscribeText[userSelectedLanguage]}
                  </span>
                  <br></br>
                  <span>
                    {TranslationFile.FooterSubscribeText2[userSelectedLanguage]}
                  </span>
                </p>

                <div className="footer--searchbox-wrap">
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2 ,1fr)",
                    }}
                    className={"footer--bottom--form"}
                  >
                    <Toast
                      ref={toast}
                      position="bottom-right"
                      className="news-letter-toast"
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder={
                        TranslationFile.ContactUsEmail[userSelectedLanguage]
                      }
                      className="newsletter-Email-User"
                      autocomplete="off"
                      required
                      oninvalid="this.setCustomValidity('Please Enter a valid Email')"
                      oninput="this.customerValidity('')"
                    />

                    {isLoading ? (
                      <div className="loader"> </div>
                    ) : (
                      <Button
                        type="submit"
                        icon="pi pi-send"
                        outlined
                        className="news-button"
                      />
                    )}
                  </form>
                </div>
              </div>
            </div>

            <div className={"footer--bottom__col"}>
              <div className="footer--bottom__cell--sub-links">
                <Row className={"terms-row"} style={{ cursor: "default" }}>
                  <Col>
                    <p
                      style={{
                        color: "white",
                        marginTop: "2px",
                        cursor: "default",
                        fontSize: "15px",
                        marginBottom: "4px",
                      }}
                    >
                      © RebelSkool enabling prosperITy AB, Gällstad | Utvecklar
                      tjänster för småföretagarna sedan 2015
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
