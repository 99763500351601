import React from "react";
import axios from "axios";
import { setUserSessionAuthKey } from "../../Component/commonUtils";
let userSelectedLanguage;

let recipient = "",
  userGUID = "",
  userDetailsResponse = "";
let userDetailResArray = [];
let userSessionAuthKeyReturnValue = setUserSessionAuthKey();

export const SignUpVerifyEmail = (emailId, Password) => {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");

  let userEmailPasswordDetails = {
    emailID: emailId,
    password: Password,
  };

  axios.get("/user/getByEmail?emailId=" + emailId).then((response) => {
    userDetailsResponse = response.data;
    userDetailsResponse.emailVerificationStatus = false;
    recipient = response.data.emailID;
    userGUID = response.data.userGUID;

    // let temp = localStorage.getItem("RBLAllUserDetails") || [];
    // if (temp.length > 0) {
    //   temp = JSON.parse(temp);
    // }
    // temp.push(userDetailsResponse);

    // localStorage.setItem("RBLAllUserDetails", JSON.stringify(temp));

    let userTimeZone = localStorage.getItem("userTimeZone");

    let sendMailObject = {
      emailType: "emailVerification",
      recipient: recipient,
      status: "New",
      noOfAttempts: 0,
      userGUID: userGUID,
    };

    // console.log(sendMailObject,"!!!!!")
    const url = "/emailQueueController/insertEmailQueueAndSendMail";
    const formData = new FormData();
    const objString = JSON.stringify(sendMailObject);

    // formData.append("file", file);
    formData.append("request", objString);
    const config = {
      headers: {
        "content-type": "multipart/form-data,application/json",
        timeZone: userTimeZone,
        language: userSelectedLanguage

      },
    };

    axios.post(url, formData, config).then((response) => {
      if (response.data) {
      }
    });
  });
};
