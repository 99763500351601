import React, { useState, useEffect } from "react";
import Config from "../../Configuration/Config";
import TranslationFile from "../Translation/Translation_corp.json";
import "./StatusAlert.css";

export default function StatusAlert() {
  let userSelectedLanguage = localStorage.getItem("selectedLanguage");
  let corpStatusBandLS = localStorage.getItem("StatusBand");
  corpStatusBandLS = corpStatusBandLS && JSON.parse(corpStatusBandLS);

  const [statusBarMsg, setStatusBarMsg] = useState("");
  const [statusBarColor, setStatusColor] = useState("");

  useEffect(() => {
    if (userSelectedLanguage == null) {
      userSelectedLanguage = "sw";
    }
    if (userSelectedLanguage == "sw") {
      setStatusBarMsg(corpStatusBandLS.statusTextSw);
    } else {
      setStatusBarMsg(corpStatusBandLS.statusTextEn);
    }
    setStatusColor(corpStatusBandLS.statusColor);
  }, []);

  return (
    <div
      className="status-alert-div"
      style={{ backgroundColor: statusBarColor }}
    >
      <center>
        <p className="statusAlterP-tag">{statusBarMsg}</p>
      </center>
    </div>
  );
}
