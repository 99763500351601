import React, { Component } from "react";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Image } from "primereact/image";
import logo from "../../Assests/RebelSkool.png";
import axios from "axios";
import { Toast } from "primereact/toast";
import { connect } from "react-redux";
import TranslationFile from "../Translation/Translation_corp.json";
import "./ForgetPassword.css";
import "./Login.css";

// import { InputMask } from "primereact/inputmask";
// import { RadioButton } from "primereact/radiobutton";
// import { Checkbox } from "primereact/checkbox";
// import { validateEmail } from "./utils";
// import { Dialog } from "primereact/dialog";
// import { Divider } from "primereact/divider";
// import { TabView, TabPanel } from "primereact/tabview";
// import { InputText } from "primereact/inputtext";
// import { Sidebar } from "primereact/sidebar";
// import usePasswordValidator from "./usePasswordValidator";

let userSelectedLanguage;
const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    incomeStatement: state.incomeStatement.incomeStatement,
    sessionDetails: state.sessionDetails,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    language: state.language.language,
    activeSideBar: state.activeSideBar.activeSideBar,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
    login: state.login.login,
    pricesToLogin: state.pricesToLogin.pricesToLogin,
  };
};

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetEmail: "",
      resetPassword: "",
      resetConfirmPassword: "",
      isResetPasswordMismatch: true,
      isResetConfirmPasswordBlur: false,
    };
  }

  resetPasswordOnChange(e) {
    this.setState({
      resetPassword: e,
    });
  }

  resetPasswordOnBlur() {
    const { resetConfirmPassword, resetPassword } = this.state;
    if (resetConfirmPassword != "") {
      if (resetPassword == resetConfirmPassword) {
        this.setState({
          isResetPasswordMismatch: false,
        });
      } else {
        this.setState({
          isResetPasswordMismatch: true,
        });
      }
    }
  }

  resetConfirmPasswordOnChange(e) {
    this.setState({
      resetConfirmPassword: e,
    });
  }

  resetConfirmPasswordOnBlur() {
    const { resetConfirmPassword, resetPassword, isResetConfirmPasswordBlur } =
      this.state;
    if (resetConfirmPassword != "") {
      this.setState({
        isResetConfirmPasswordBlur: true,
      });
      if (resetConfirmPassword == resetPassword) {
        this.setState({
          isResetPasswordMismatch: false,
        });
      } else {
        this.setState({
          isResetPasswordMismatch: true,
        });
      }
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    let navigateTo = localStorage.getItem("navigateTo");
    if (navigateTo == "dashboard") {
      localStorage.setItem("navigateTo", "");
      window.location.assign("/#/annualreport");
    }
  }

  resetTheNewPassword() {
    const { resetPassword, resetConfirmPassword } = this.state;
    const { login } = this.props;
    if (resetPassword != "" && resetConfirmPassword != "") {
      let currentPageUrl = window.location.href;
      let splitCurrentPageUrl = currentPageUrl.split("/");
      let userGUID = "";
      if (splitCurrentPageUrl.length == 7) {
        userGUID = splitCurrentPageUrl[5];
      }

      let userResetPasswordWithUserGUID = {
        userGUID: userGUID,
        password: resetPassword,
      };

      axios
        .post("/user/updatePasswordForUserGUID", userResetPasswordWithUserGUID)
        .then((response) => {
          if (response.data) {
            this.setState({ resetPassword: "", resetConfirmPassword: "" });
            this.toast.show({
              severity: "success",
              summary: TranslationFile.SuccessText[userSelectedLanguage],
              detail: TranslationFile.PassResetSuces[userSelectedLanguage],
              life: 3000,
            });

            // setTimeout(() => {
            //   window.location.assign("/#/annualreport");
            // }, 3000);

            axios
              .get("/user/getByGuid?userGUID=" + userGUID)
              .then((userDetailsResponse) => {
                if (userDetailsResponse.data) {
                  let resp = userDetailsResponse.data;
                  let emailIdFromResp = resp.emailID;

                  let userEmailPasswordDetails = {
                    emailID: emailIdFromResp,
                    password: resetPassword,
                  };

                  if (
                    userEmailPasswordDetails.emailID != "" &&
                    userEmailPasswordDetails.password != ""
                  ) {
                    this.toast.show({
                      severity: "success",
                      summary:
                        TranslationFile.SuccessText[userSelectedLanguage],
                      detail:
                        TranslationFile.redirectingToHome[userSelectedLanguage],
                      // life: 3000,
                      sticky: true,
                    });
                    axios
                      .post("/user/loginAndGetUser", userEmailPasswordDetails)
                      .then((loginResponse) => {
                        const userAuthKey = response.headers.usersessionauthkey;
                        login.values = loginResponse.data;
                        loginResponse.data["userSessionAuthKey"] = userAuthKey;
                        localStorage.setItem(
                          "rebelSkoolUser",
                          JSON.stringify(loginResponse.data)
                        );
                        localStorage.setItem("navigateTo", "dashboard");
                        window.location.reload(false);
                      });
                  } else {
                    this.toast.show({
                      severity: "error",
                      summary: TranslationFile.errorText[userSelectedLanguage],
                      detail:
                        TranslationFile.PassEmptyMes[userSelectedLanguage],
                      life: 3000,
                    });
                  }
                }
              });
          }
        });
    }
  }

  render() {
    const {
      resetEmail,
      resetPassword,
      resetConfirmPassword,
      isResetPasswordMismatch,
      isResetConfirmPasswordBlur,
    } = this.state;
    return (
      <div className="forgot-password-main-div">
        <Toast ref={(el) => (this.toast = el)} style={{ marginTop: "80px" }} />
        <div className="forget-password-container">
          <center>
            <div className="forget-password-logo">
              {/* <Link to="/"> */}
              <Image src={logo} alt="Image" width="200" />
              {/* </Link> */}
            </div>
          </center>

          <Fieldset legend="Forgot Password?">
            <div>
              <div>
                <center>Enter your New Password to reset your Password.</center>
                <br></br>
                <center>
                  <Password
                    value={resetPassword}
                    onChange={(e) => {
                      this.resetPasswordOnChange(e.target.value);
                    }}
                    onBlur={() => this.resetPasswordOnBlur()}
                    placeholder="New Password"
                    toggleMask
                    feedback={false}
                    className="forget-password"
                  />

                  <br></br>
                  <br></br>
                  <Password
                    value={resetConfirmPassword}
                    onChange={(e) => {
                      this.resetConfirmPasswordOnChange(e.target.value);
                    }}
                    onBlur={() => this.resetConfirmPasswordOnBlur()}
                    placeholder="Confirm New Password"
                    toggleMask
                    feedback={false}
                    className="forget-password"
                  />
                  {isResetPasswordMismatch &&
                    resetPassword != "" &&
                    resetConfirmPassword != "" &&
                    isResetConfirmPasswordBlur && (
                      <div className="error">The passwords must match.</div>
                    )}

                  <br></br>
                </center>
              </div>
              <center>
                {resetPassword != "" &&
                  resetConfirmPassword != "" &&
                  resetPassword == resetConfirmPassword &&
                  !isResetPasswordMismatch ? (
                  <Button
                    label="Reset Password"
                    aria-label="year-btn-save"
                    className="year-btn-save"
                    style={{
                      width: "250px",
                      height: "44px",
                      fontSize: "1.2rem",
                      marginTop: "6%",
                      color: "white",
                    }}
                    onClick={() => this.resetTheNewPassword()}
                  />
                ) : (
                  <Button
                    label="Reset Password"
                    aria-label="year-btn-save"
                    className="year-btn-save"
                    style={{
                      width: "250px",
                      height: "44px",
                      fontSize: "1.2rem",
                      marginTop: "6%",
                      color: "white",
                    }}
                    disabled
                  />
                )}
              </center>
            </div>
          </Fieldset>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(ForgetPassword);
