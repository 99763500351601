import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

export default function PlayGround() {
  const [date, setDate] = useState(null);

  addLocale("sv", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ],
    dayNamesShort: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
    dayNamesMin: ["S", "M", "T", "O", "T", "F", "L"],
    monthNames: [
      "Januari",
      "Februari",
      "Mars",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "Augusti",
      "September",
      "Oktober",
      "November",
      "December"
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Maj",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec"
    ],
    today: "Idag",
    clear: "Rensa",
  });

  return (
    <div className="card flex justify-content-center">

      <Calendar value={date} onChange={(e) => setDate(e.value)} locale="sv" />
    </div>
  );
}
